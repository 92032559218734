<template>
  <div class="integral-exchange-container">
    <p class="integral-exchange-title">积分兑换</p>
    <p class="integral-exchange-phone">{{userInfo.phone}}</p>
    <p class="integral-exchange-user">当前用户可用积分{{ userInfo.integral }}个</p>
    <p class="integral-exchange-tip">{{gashaponInfo.integralNum}}积分：1次抽奖次数</p>
    <div class="integral-exchange-content row-center">
      <div class="integral-input-content col-center">
        <div class="row-center integral-input-content-left">
          {{userIntegral}}
        </div>
        <p>积分</p>
      </div>
      <img
        class="gashapon-exchange-icon"
        src="../../assets/icon/gashapon/next.png"
      />
      <div class="lottery-input-content col-center">
        <div class="lottery-input-content-input">
          <van-field
            :autofocus="true"
            type="digit"
            v-model="lotterySize"
          />
        </div>

        <p>抽奖次数</p>
      </div>
    </div>
    <div
      @click="exchange"
      class="exchange-button"
    ></div>
    <el-dialog
      title="尚未设置资产密码"
      :visible.sync="donationVisible"
      @confirm="$router.push('/resetPwd')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="donationVisible = false">取 消</el-button>
        <div
          @click="$router.push('/resetPwd')"
          class="confirm-button row-center"
        >去设置</div>
      </span>
    </el-dialog>
    <van-popup
      closeable
      v-model="show"
      position="bottom"
      style="height: 411px;background: rgba(21, 21, 21, 0.85);"
      :close-on-click-overlay="false"
    >
      <div v-if="payStep == 1">
        <p class="donation-popup-title">安全验证</p>
        <!-- 密码输入框 -->
        <p class="donation-popup-tip">验证资产密码</p>
        <van-password-input
          :value="pwdValue"
          :gutter="8"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
        />
        <!-- 数字键盘 -->
        <van-number-keyboard
          v-model="pwdValue"
          :show="showKeyboard"
          @blur="show = false"
        />
      </div>
    </van-popup>
    <!-- 弹窗 -->
    <div
      v-if="lottertShade"
      class="lottery-shade-bg"
    >
    </div>
    <div
      v-if="lottertShade"
      @click="closeLotteryExchange()"
      class="lottery-content"
    >
      <div class="lottery-content-bg col-center">
        <img src="../../assets/icon/gashapon/right.png" />
        <p class="lottery-content-title">兑换成功</p>
        <p class="lottery-content-tip">抽奖次数+{{lotterySize}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import api from '../../api/index-client'
import md5 from 'js-md5';
import { Field,PasswordInput,NumberKeyboard,Popup } from 'vant';
import { Dialog,Button } from 'element-ui';
Vue.use(Dialog);
Vue.use(Field);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Button);
Vue.use(Popup);

export default {
  data () {
    return {
      lottertShade: false,
      payStep: 1,
      pwdValue: '',
      showKeyboard: true,
      show: false,
      donationVisible: false,
      lotterySize: 0,
      userInfo: {},
      gashaponInfo: {},
      userIntegral: 0
    }
  },
  watch: {
    pwdValue () {
      if (this.pwdValue.length === 6) {
        this.testOldPwd()
      }
    },
    lotterySize () {
      if (!this.lotterySize || this.lotterySize == 0) {
        this.userIntegral = 0
        this.lotterySize = 0
      } else {
        if ((parseInt(this.lotterySize) * parseInt(this.gashaponInfo.integralNum)) > parseInt(this.userInfo.integral)) {
          this.lotterySize = (parseInt(this.userInfo.integral) / parseInt(this.gashaponInfo.integralNum))
          this.userIntegral = (parseInt(this.userInfo.integral) / parseInt(this.gashaponInfo.integralNum)) * this.gashaponInfo.integralNum
        } else {
          this.userIntegral = parseInt(this.lotterySize) * parseInt(this.gashaponInfo.integralNum)
          this.lotterySize = (parseInt(this.userIntegral) / parseInt(this.gashaponInfo.integralNum))
        }
      }
    }
  },
  mounted () {
    // 获取用户积分数据
    this.getUserInfo()
  },
  methods: {
    closeLotteryExchange () {
      this.lottertShade = false
      this.$router.go(-1);
    },
    // 校验资产密码
    testOldPwd () {
      api
        .post('user/verifyAssetsPassword',{ assetsPassword: md5(this.pwdValue) })
        .then(result => {
          if (result.data.success) {
            this.exchangeIntegral()
          } else {
            this.pwdValue = ''
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 积分兑换
    exchangeIntegral () {
      api
        .post('lottery/exchangeIntegral',{ lotteryCode: this.$route.query.lotteryCode,exchangeCount: this.lotterySize,pwd: md5(this.pwdValue) })
        .then(result => {
          if (result.data.success) {
            this.show = false
            this.lottertShade = true
          } else {
            this.pwdValue = ''
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取用户是否实名状态
    getUserInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            this.userInfo = result.data.data
            this.getExchangePlans()
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    exchange () {
      if (this.userInfo.hasAssetsPassword) {
        this.show = true
      } else {
        this.donationVisible = true
      }
    },
    // 获取用户兑换方案
    getExchangePlans () {
      api
        .get('lottery/detail/' + this.$route.query.lotteryCode)
        .then(result => {
          if (result.data.success) {
            this.gashaponInfo = result.data.data
            this.$store.commit('HIDE_APPLOADING')

          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
  },
}
</script>
<style lang="less" scoped>
.integral-exchange-container {
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  background: #fffaf4;
  .integral-exchange-title {
    width: 100%;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 900;
    color: #1c1c1c;
    line-height: 1.5625rem;
    padding-top: 1.5rem;
  }
  .integral-exchange-phone {
    font-size: 16px;
    font-weight: 500;
    color: #080808;
    margin-top: 30px;
    width: 100%;
    text-align: center;
  }
  .integral-exchange-user {
    font-size: 15px;
    font-weight: 500;
    color: #080808;
    margin-top: 15px;
    width: 100%;
    text-align: center;
  }
  .integral-exchange-tip {
    font-size: 12px;
    font-weight: 500;
    color: #080808;
    margin-top: 15px;
    width: 100%;
    text-align: center;
  }
  .integral-exchange-content {
    width: 90vw;
    margin-left: 5vw;
    height: 140px;
    margin-top: 40px;
    background: #f3ddd4;
    border-radius: 10px;
  }
  .gashapon-exchange-icon {
    width: 30px;
    height: auto;
    transform: rotate(90deg);
    margin: 0 30px;
    margin-bottom: 30px;
  }
  .integral-input-content,
  .lottery-input-content {
    width: 100px;
    height: auto;
    .integral-input-content-left {
      width: 100px;
      height: 58px;
      background: #de8466;
      border-radius: 10px;
      font-size: 24px;
      font-weight: bold;
      color: #ffffff !important;
      cursor: pointer;
    }
    .lottery-input-content-input {
      width: 100px;
      height: 58px;
      background: #de8466;
      border-radius: 10px;
      font-size: 24px;
      font-weight: bold;
      color: #ffffff !important;
    }
    .van-cell {
      width: 100px;
      height: 58px;
      background: #de8466;
      border-radius: 10px;
      font-size: 24px;
      font-weight: bold;
    }
    /deep/.van-field__control {
      color: #ffffff !important;
      text-align: center;
      border-bottom: 1px solid;
    }
    p {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 500;
      color: #080808;
    }
  }
  .exchange-button {
    margin-left: 20vw;
    width: 60vw;
    height: 16vw;
    margin-top: 200px;
    background-image: url('../../assets/icon/gashapon/exchange-button.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  .exchange-button:active {
    opacity: 0.6;
  }
}
.van-popup {
  .donation-popup-title {
    margin: 1.625rem 0 1.25rem 0;
    text-align: center;
    font-size: 1.125rem;
    font-family: lantingheiweight;
    font-weight: 700;
    color: #f0f0f0;
  }
  .donation-popup-donationPay {
    text-align: center;
    font-size: 2.1875rem;
    font-family: SFUIDisplay-Bold, SFUIDisplay;
    font-weight: bold;
    color: #ffffff;
    line-height: 2.625rem;
    margin-bottom: 2.4375rem;
  }
  .donation-popup-tip {
    margin: 0 0 0.875rem 1rem;
    font-size: 0.875rem;
    font-family: lantingheiweight;
    font-weight: 600;
    color: #666666;
    line-height: 19px;
  }
  .van-password-input__security li {
    background: #565656;
    border-radius: 0.25rem;
  }
  .van-number-keyboard {
    background: rgba(21, 21, 21, 0.85);
  }
  /deep/.van-number-keyboard .van-key {
    background-color: #646465;
    box-shadow: 0px 1px 0px 0px #000000;
    font-family: Helvetica;
    color: #ffffff;
  }
}
.lottery-shade-bg {
  z-index: 1997;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000000;
  opacity: 0.5;
}
.lottery-content {
  z-index: 1998;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  .lottery-content-bg {
    width: 80vw;
    height: 80vw;
    margin: 30vw 10vw 0 10vw;
    background: #f9dcd4;
    border-radius: 10px;
    img {
      width: 30%;
      height: 30%;
    }
    .lottery-content-title {
      font-size: 22px;
      font-weight: bold;
      color: #3d3d3d;
      margin: 60px 0 20px 0;
    }
    .lottery-content-tip {
      font-size: 16px;
      font-weight: 800;
      color: #3d3d3d;
    }
  }
}
</style>